<template>
<div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    {{ param.headerTitle }}
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
    <div>
      <template>
        <div class="box-ct">
        <v-form ref="form"
                v-model="valid"
                lazy-validation
        >
        <div class="table-form">
          <table>
            <caption class="hide">
              사용자정보 항목
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="160px" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">게시판ID</v-badge>
              </th>
              <td>
                  <v-text-field
                      class="form-inp full"
                      name="name"
                      value=""
                      id="BRD_ID"
                      v-model="BRD_ID"
                      :rules="validateRules.brdId"
                      outlined
                      required
                      :disabled="paramData.p02"
                  >
                  </v-text-field>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">게시판명</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="BRD_NAME"
                    v-model="BRD_NAME"
                    :rules="validateRules.brdName"
                    outlined
                    required
                    :disabled="paramData.p02"
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                게시판설명
              </th>
              <td  colspan="3">
                <v-textarea
                  class="textarea-type0"
                  outlined
                  name="input-7-4"
                  value=""
                  id="BRD_RMK"
                  v-model="BRD_RMK"
                  no-resize
                  rows="3"
                ></v-textarea>
              </td>
            </tr>
            <tr>
              <th scope="row">
                게시판유형
              </th>
              <td colspan="3">
                <v-radio-group
                    v-model="radioGroupSelected"
                    row
                    class="radio-inline"
                    v-on:change="radioChg"
                >
                  <v-radio
                      v-for="(rad01, index) in radioGroup"
                      :key="radioGroup[index].text"
                      :label="rad01.text"
                      :on-icon="'svg-rad-on'"
                      :off-icon="'svg-rad-off'"
                      :value="rad01.value"
                  >
                  </v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <th scope="row">
                게시판기능
              </th>
              <td colspan="3">
                <v-checkbox
                    v-for="(chk01,index) in chkTypes"
                    :key="chkTypes[index].text"
                    :label="chk01.text"
                    :on-icon="'svg-chk-on'"
                    :off-icon="'svg-chk-off'"
                    v-model="chk01.selected"
                    :disabled="chk01.disabled"
                    @change="chkChg(index)"
                    class="d-inline-flex" :class="[chk01.isLast !== true ? 'pr-4' : '']"
                    ref="checkBoxInfo"
                ></v-checkbox>

              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">사용여부</v-badge>
              </th>
              <td>
                  <div class="form-inp full">
                    <v-select
                        :items="dropYn"
                        item-text="title"
                        item-value="value"
                        outlined
                        placeholder="선택하세요"
                        :rules="validateRules.useYn"
                        v-model="USE_YN"
                        required
                    >
                    </v-select>
                  </div>
              </td>
              <th scope="row">
               New 표시일
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="NEW_DAY"
                    type="number"
                    id="NEW_DAY"
                    min="0"
                    max="9999"
                    v-model="NEW_DAY"
                    :rules="validateRules.chkNum"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                기타정보1(숫자)
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="SORT_ORD"
                    type="number"
                    id="ETC01"
                    min="0"
                    max="9999"
                    v-model="ETC01"
                    :rules="validateRules.chkNum"
                ></v-text-field>
              </td>
              <th scope="row">
                기타정보2(숫자)
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="SORT_ORD"
                    type="number"
                    id="ETC02"
                    min="0"
                    max="9999"
                    v-model="ETC02"
                    :rules="validateRules.chkNum"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                기타정보3(문자)
              </th>
              <td  colspan="3">
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="ETC03"
                    outlined
                    v-model="ETC03"
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                기타정보4(문자)
              </th>
              <td  colspan="3">
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="ETC04"
                    outlined
                    v-model="ETC04"
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                기타정보5(문자)
              </th>
              <td  colspan="3">
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="ETC05"
                    outlined
                    v-model="ETC05"
                >
                </v-text-field>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        </v-form>
        </div>
      </template>
    </div>
    <div class="text-right mt-3">
      <v-btn
          v-if="this.mixin_set_btn(this.$options.name, 'btnSave')"
          outlined
          class="btn-point ml-2"
          id="btnSave01"
          @click="saveBrd(paramData.DATA_FLAG)"
      >저장</v-btn>
      <v-btn
          v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')"
          outlined
          class="btn-etc ml-2"
          id="btnDel"
          @click="deleteBtn(paramData.DATA_FLAG)"
      >삭제</v-btn>
    </div>
  </div>
</div>

</template>

<script>

import api from "@/store/apiUtil";
import {mixin} from "../../mixin/mixin";

export default {
  name: "MENU_M810501P01", //name은 'MENU_' + 파일명 조합
  mixins:[mixin],
  props: {
    param:{
      type: Object,
    },
    headerTitle: {
      type: String,
      default: "제목",
    },
  },
  data() {
    return{
    dropItemRemove : true,
    removeDropitem(index) {
      this.selectedCustKey.splice(index, 1);
    },
    selectedCustKey : [],
    footerHideTitle: "닫기",

    //라디오설정
    radioGroupSelected : '',
    radioGroup: [
      {
        text: "게시판",
        value : "BRD",
      },
      {
        text: "실시간공지사항",
        value : "RLTM_NOTICE",
      },
      {
        text: "이미지",
        value : "IMG",
      },
    ],
    chkTypes :[
      {
        text: "공지사항",
        selected: false,
        disabled: false,
        value: "N"
      },
      {
        text: "답변허용",
        selected: false,
        disabled: false,
        value: "N"
      },
      {
        text: "파일첨부",
        selected: false,
        disabled: false,
        value: "N"
      },
      {
        text: "실시간공지여부",
        selected: false,
        isLast: true,
        disabled: false,
        value: "N"
      },
    ],

    dropItems: [],
    dropYn:[
      { title: "전체", value:"" },
      { title: "사용", value:"Y" },
      { title: "미사용", value:"N" },
    ],

      //v-model
      detailList:[],
      groupAddList:[],
      ASP_NEWCUST_KEY:'',
      USE_YN : 'Y',
      ATRT_GROUP_CD : '',
      ATTR_DIV_CD : '',
      ATTR_DIV_NM: '',
      SORT_ORD:'',
      footerYn : false,
      BRD_ID : '',
      BRD_NAME : '',
      BRD_RMK : '',
      NEW_DAY : 0,
      ETC01 : '',
      ETC02 : '',
      ETC03 : '',
      ETC04 : '',
      ETC05 : '',
      PROC_ID: 'parkij',
      RMK_YN : 'N', //as is 입력 막아져있음
      BRD_TY : '',
      getRtNotiYN : '',
      getRtNotiBrdId : '',

      p02: '',

    //valid관련 데이터
    valid:true,
      validateRules:{
      brdId: [
        v => !!v || '게시판ID는 필수입니다.',
        // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
      ],
      brdName: [
        v => !!v || '게시판명은 필수입니다.',
      ],
      useYn:[
        v => !!v || '사용여부는 필수입니다.',
      ],
      chkNum:[
        v => /[0-9]/.test(v) || '숫자만 입력 가능합니다.',
        v => (v >= 0 && v < 10000 ) || '0 ~ 9999 이내로 입력해 주세요.',
      ] ,
      }
    }
  },

  methods:{
    init(){
      this.dropItems = this.$store.getters['userStore/GE_USER_COMPANY'];

      this.selectRtNotiRtn();
    },

    radioChg(item){
      //disabled초기화
      this.chkTypes[0].disabled = false;
      this.chkTypes[1].disabled = false;
      this.chkTypes[2].disabled = false;
      this.chkTypes[3].disabled = false;

      if(item == "BRD"){
        //셀렉트박스 체크
        this.chkTypes[1].selected = true;
        this.chkTypes[2].selected = true;
        this.chkTypes[3].selected = false;

        //disabled처리
        this.chkTypes[3].disabled = true;
      }else if(item == "RLTM_NOTICE"){
        //셀렉트박스 체크
        this.chkTypes[0].selected = false;
        this.chkTypes[1].selected = false;
        this.chkTypes[2].selected = true;
        this.chkTypes[3].selected = true;

        //disabled처리
        this.chkTypes[0].disabled = true;
        this.chkTypes[1].disabled = true;
      }else if(item == "IMG"){
        //셀렉트박스 체크
        this.chkTypes[0].selected = false;
        this.chkTypes[1].selected = false;
        this.chkTypes[2].selected = true;
        this.chkTypes[3].selected = false;

        //disabled처리
        this.chkTypes[0].disabled = true;
        this.chkTypes[1].disabled = true;
        this.chkTypes[3].disabled = true;
      }

      for(let i in this.chkTypes){
        if(this.chkTypes[i].selected == true){
          this.chkTypes[i].value = "Y";
        }else{
          this.chkTypes[i].value = "N";
        }
      }


    },

    chkChg(index){
      //게시판 유형 게시판일때 체크박스 분기
      if(index == 0){
        this.chkTypes[1].selected = false
      }else if(index == 1){
        this.chkTypes[0].selected = false
      }

      for(let i in this.chkTypes){
        if(this.chkTypes[i].selected == true){
          this.chkTypes[i].value = "Y";
        }else{
          this.chkTypes[i].value = "N";
        }
      }

    },
    deleteBtn(DATA_FLAG){
      if (Object.keys(this.paramData).length != 0) {
        let message = "해당 소속정보를 삭제하시겠습니까?"
        let iconType = "noti"
        this.common_confirm(message, this.deleteBrd, DATA_FLAG, null, null, iconType);
      }else if(Object.keys(this.paramData).length == 0){
        this.common_alert("선택된 항목이 존재하지 않습니다.", "error")
      }
    },
    async deleteBrd(DATA_FLAG) {
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/setting/board/manage/delete";
      requestData.headers["SERVICE"] = "setting.board.manage";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["CALL_BACK"] = "SettingBoardManage.processRtn_callback";

      // sendData 세팅
      requestData.sendData["DATA_FLAG"] = DATA_FLAG;
      requestData.sendData["BRD_ID"] = this.BRD_ID;
      requestData.sendData["BRD_NAME"] = this.BRD_NAME;
      requestData.sendData["BRD_RMK"] = this.restoreXSS_CKeditor(this.BRD_RMK);
      requestData.sendData["BRD_TY01"] = '';
      requestData.sendData["BRD_TY02"] = '';
      requestData.sendData["BRD_TY03"] = '';
      requestData.sendData["NOTI_YN"] = this.chkTypes[0].value;
      requestData.sendData["REPLE_YN"] = this.chkTypes[1].value;
      requestData.sendData["FILE_YN"] = this.chkTypes[2].value;
      requestData.sendData["RT_NOTI_YN"] = this.chkTypes[3].value;
      requestData.sendData["USE_YN"] = this.USE_YN;
      requestData.sendData["NEW_DAY"] = this.NEW_DAY;
      requestData.sendData["ETC01"] = this.ETC01;
      requestData.sendData["ETC02"] = this.ETC02;
      requestData.sendData["ETC03"] = this.ETC03;
      requestData.sendData["ETC04"] = this.ETC04;
      requestData.sendData["ETC05"] = this.ETC05;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.paramData.ASP_NEWCUST_KEY;
      requestData.sendData["PROC_ID"] = this.PROC_ID;
      requestData.sendData["RMK_YN"] = this.RMK_YN;
      requestData.sendData["BRD_TY"] = this.radioGroupSelected;

      console.log("requestData");
      console.log(requestData);
      let boardDelResponse = await this.common_postCall(requestData);
      this.boardDelCallback(boardDelResponse);
    },
    boardDelCallback(response){
      if(response.HEADER.ERROR_FLAG == false){
        let msg = "정상처리 되었습니다.";

        //alert창 띄우기
        this.common_alert(msg, "done");
        this.$emit('submit');
      }

    },

    //실시간 공지 게시판 사용 여부 조회
    async selectRtNotiRtn(){
      let requestData = {
        headers: {},
        sendData:{}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/board/manage/rltm-notice-use-at/inqire";
      requestData.headers["SERVICE"] = "setting.board.manage.rltm-notice-use-at";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      let selectRtNotiRtnResponse = await this.common_postCall(requestData);
      this.selectRtNotiRtnCallback(selectRtNotiRtnResponse);
    },

    selectRtNotiRtnCallback(response){
      if(response.HEADER.ERROR_FLAG == false){
        if(Object.keys(response.DATA).length > 0){
          this.getRtNotiYN = response.DATA[0].RT_NOTI_YN;
          this.getRtNotiBrdId = response.DATA[0].BRD_ID;
        }
      }

    },

    async saveBrd(DATA_FLAG) {

      //실시간 공지 유무 체크
      if((this.getRtNotiYN=="Y") && (this.chkTypes[3].selected == true) && (this.BRD_ID != this.getRtNotiBrdId)){
        let msg ="실시간 공지 게시판이 이미 있습니다";
        this.common_alert(msg, "error");

        return;
      }


      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/setting/board/manage/regist";
      requestData.headers["SERVICE"] = "setting.board.manage";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["CALL_BACK"] = "SettingBoardManage.processRtn_callback";

      // sendData 세팅
      requestData.sendData["DATA_FLAG"] = DATA_FLAG;
      requestData.sendData["BRD_ID"] = this.BRD_ID;
      requestData.sendData["BRD_NAME"] = this.BRD_NAME;
      requestData.sendData["BRD_RMK"] = this.restoreXSS_CKeditor(this.BRD_RMK);
      requestData.sendData["BRD_TY01"] = '';
      requestData.sendData["BRD_TY02"] = '';
      requestData.sendData["BRD_TY03"] = '';
      requestData.sendData["NOTI_YN"] = this.chkTypes[0].value;
      requestData.sendData["REPLE_YN"] = this.chkTypes[1].value;
      requestData.sendData["FILE_YN"] = this.chkTypes[2].value;
      requestData.sendData["RT_NOTI_YN"] = this.chkTypes[3].value;
      requestData.sendData["USE_YN"] = this.USE_YN;
      requestData.sendData["NEW_DAY"] = this.NEW_DAY;
      requestData.sendData["ETC01"] = this.ETC01;
      requestData.sendData["ETC02"] = this.ETC02;
      requestData.sendData["ETC03"] = this.ETC03;
      requestData.sendData["ETC04"] = this.ETC04;
      requestData.sendData["ETC05"] = this.ETC05;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.paramData.ASP_NEWCUST_KEY;
      requestData.sendData["PROC_ID"] = this.PROC_ID;
      requestData.sendData["RMK_YN"] = this.RMK_YN;
      requestData.sendData["BRD_TY"] = this.radioGroupSelected;

      //radio 선택 안했을때
      if(this.chkTypes[0].selected == false && this.chkTypes[1].selected == false && this.chkTypes[2].selected == false && this.chkTypes[3].selected == false){
        requestData.sendData["NOTI_YN"] = 'N';
        requestData.sendData["REPLE_YN"] = 'N';
        requestData.sendData["FILE_YN"] = 'N';
        requestData.sendData["RT_NOTI_YN"] = 'N';
        requestData.sendData["BRD_TY"] = 'IMG';
      }

      let boardAddResponse = await this.common_postCall(requestData);
      this.boardAddCallback(boardAddResponse);
    },
    boardAddCallback(response){
      if(response.HEADER.ERROR_FLAG == false){
        let msg = "정상처리 되었습니다.";

        this.selectRtNotiRtn();

        //alert창 띄우기
        this.common_alert(msg, "done");

        this.$emit('submit');
      }
    },

    //validation체크
    validate() {
      return this.$refs.form.validate();
    },
  },

  mounted() {
    this.init();
    if(this.paramData.gridListDataText){
      this.BRD_ID = this.paramData.gridListDataText.BRD_ID;
      this.BRD_CNT = this.paramData.gridListDataText.BRD_CNT;
      this.BRD_NAME = this.paramData.gridListDataText.BRD_NAME;
      this.BRD_RMK = this.paramData.gridListDataText.BRD_RMK;
      this.BRD_TY = this.paramData.gridListDataText.BRD_TY;
      this.ETC01 = this.paramData.gridListDataText.ETC01;
      this.ETC02 = this.paramData.gridListDataText.ETC02;
      this.ETC03 = this.paramData.gridListDataText.ETC03;
      this.ETC04 = this.paramData.gridListDataText.ETC04;
      this.ETC05 = this.paramData.gridListDataText.ETC05;
      this.FILE_YN = this.paramData.gridListDataText.FILE_YN;
      this.NEW_DAY = this.paramData.gridListDataText.NEW_DAY;
      this.NOTI_YN = this.paramData.gridListDataText.NOTI_YN;
      this.REPLE_YN = this.paramData.gridListDataText.REPLE_YN;
      this.RMK_YN = this.paramData.gridListDataText.RMK_YN;
      this.RT_NOTI_YN = this.paramData.gridListDataText.RT_NOTI_YN;
      this.USE_YN = this.paramData.gridListDataText.USE_YN;
      this.USE_YN_NAME = this.paramData.gridListDataText.USE_YN_NAME;

      console.log("this.BRD_TY");
      console.log(this.BRD_TY);

      //상세화면 selected인 값 체크
      let radioKeys = ['BRD', 'RLTM_NOTICE', 'IMG'];
      let chkBoxKeys = ['NOTI_YN', 'REPLE_YN', 'FILE_YN', 'RT_NOTI_YN'];
      let objKeys = Object.keys(this.paramData.gridListDataText);

      for(let i in objKeys){
        for(let j in chkBoxKeys){
          if(objKeys[i] == chkBoxKeys[j]){
            if(this.paramData.gridListDataText[objKeys[i]] == "Y"){
              this.chkTypes[j].value = "Y";
              this.chkTypes[j].selected = true;
            }else{
              this.chkTypes[j].value = "N";
              this.chkTypes[j].selected = false;
            }
          }
        }
        for(let k in radioKeys){
          console.log("this.paramData.gridListDataText[objKeys[i]]");
          console.log(this.paramData.gridListDataText[objKeys[i]]);
          if(this.paramData.gridListDataText[objKeys[i]] == radioKeys[k]){
            this.radioGroupSelected = radioKeys[k];
          }
        }
      }

    }
  },

  computed: {
    paramData(){
      return {
        ASP_NEWCUST_KEY : this.param.data.ASP_NEWCUST_KEY,
        gridListDataText : this.param.data.gridListDataText,
        DATA_FLAG : this.param.DATA_FLAG,
        p02 : this.param.p02,
      };
    },

  }
}
</script>

<style></style>
