<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">게시판생성관리</h1>
    <div class="box-wrap d-flex">
      <div>
        <div class="form-inp sm">
          <v-select
              :items="selBoxBiz"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              v-model="ASP_NEWCUST_KEY"
              :readonly="RDOnly"
          >
            <template v-slot:label>
              회사구분
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
          </v-select>
        </div>
        <div class="form-inp ml-2">
          <v-text-field
              outlined
              hide-details
              label="게시판ID"
              v-model="BRD_ID"
              v-on:keyup.enter="clickGetList"
          >
          </v-text-field>
        </div>
        <div class="form-inp sm ml-2">
          <v-text-field
              outlined
              hide-details
              label="게시판명"
              v-model="BRD_NAME"
              v-on:keyup.enter="clickGetList"
          >
          </v-text-field>
        </div>
        <div class="form-inp sm ml-2">
          <v-select
              :items="dropYn"
              item-text="title"
              item-value="value"
              outlined
              hide-details
              label="사용여부"
              placeholder="선택하세요"
              v-model="USE_YN"
          >
          </v-select>
        </div>
      </div>
      <div class="d-flex ml-auto align-center">
        <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')"
            class="btn-etc2"
            outlined
            @click="clickGetList"
        > 조회 </v-btn>
      </div>
    </div>
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">게시판리스트
        <div class="ml-auto">
          <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnNew')"
              class="btn-default"
              outlined
              @click="clickNewWrite"
          > 신규 </v-btn>
        </div>

      </h2>
      <div class="box-ct">
        <v-data-table
            class="grid-default case03"
            fixed-header
            height="550px"
            dense
            :headers="gridListDataHeaders"
            :items="gridListDataText"
            :items-per-page="30"
            :page.sync="page"
            hide-default-footer
            single-select
            @page-count="pageCount = $event"
            @click:row="rowClick1"
            @dblclick:row="clickListRow"
            v-model="gridListItemSelected"
            ref="table"
        >
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>

    <!-- 새글 쓰기 모달창 -->
    <div class="ml-auto">
        <v-dialog v-if="DialogM810501P01" max-width="655" persistent v-model="DialogM810501P01"
        content-class="square-modal min-auto" >
          <dialog-M810501P01
              headerTitle="사용자정보 신규등록"
              :param="M810501P01Param"
              p01
              @hide="hideDialog('M810501P01')"
              @submit="submitDialog('M810501P01')"
          ></dialog-M810501P01>
        <template v-slot:body>

        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {mixin} from "@/mixin/mixin";
import api from "@/store/apiUtil";
import DialogM810501P01 from "./M810501P01.vue";

export default {
  name: "MENU_M810501", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM810501P01,

  },
  mixins:[mixin],
  data() {
    return {
      DialogM810501P01: false,
      M810501P01Param: {},

      //사용여부 item
      dropYn:[
        { title: "전체", value:"" },
        { title: "사용", value:"Y" },
        { title: "미사용", value:"N" },
      ],

      // 조회 셀렉트박스 아이템 리스트
      selBoxBiz: [],

      // 조회 v-model
      USE_YN :'',
      BRD_NAME:'',
      BRD_ID:'',
      ASP_NEWCUST_KEY:'',

      //리스트
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,

      gridListItemSelected: [],
      gridListDataText    : [],
      gridListDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "index",
          width: "50px",
          sortable: true,
        },
        {
          text: "게시판ID",
          value: "BRD_ID",
          align: "left",
          sortable: true,
        },
        {
          text: "게시판명",
          value: "BRD_NAME",
          align: "left",
          sortable: true,
        }, //ASP_CUST_KEY
        {
          text: "게시판설명",
          value: "BRD_RMK",
          align: "left",
          sortable: true,
        },
        {
          text: "게시물수",
          value: "BRD_CNT",
          align: "center",
          sortable: true,
        },
        {
          text: "사용여부",
          value: "USE_YN",
          align: "center",
          sortable: true,
        },
      ],

      RDOnly:false,

      alertMsg: {
        selBoxBizChk: '회사구분 은(는) 필수입력입니다.',
        noCntData   : '조회된 데이터가 없습니다.',
        newWriteChk : '조회 후 작성 가능합니다.'
      }

    };
  },

  methods: {
    //Table row 클릭이벤트
    rowClick1(item, row) {
      if(item != null){
        this.activeRow(row,true);
      }
    },

    activeRow(row, value){
      console.log(row);
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    clickGetList() {
      if(this.ASP_NEWCUST_KEY == '' || this.ASP_NEWCUST_KEY == null) {
        this.common_alert(this.alertMsg.selBoxBizChk, "error");
        return;
      }
      this.getGridList();
    },

    clickNewWrite() {
      if(this.ASP_NEWCUST_KEY == '' || this.ASP_NEWCUST_KEY == null) {
        this.common_alert(this.alertMsg.newWriteChk, "error");
        return;
      }
      this.M810501P01Param = {
        headerTitle: "게시판 신규등록",
        data : {ASP_NEWCUST_KEY : this.ASP_NEWCUST_KEY},
        DATA_FLAG : "I"
      };
      this.showDialog('M810501P01')
    },

    showDialog(menu) {
      console.log("showDialog")
      this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
      console.log("hideDialog")
      this[`Dialog${menu}`] = false;
    },
    submitDialog(type) {
      console.log("완료");
      this.gridListItemSelected = [];
      this.gridListDataText = [];
      this.clickGetList();
      this[`Dialog${type}`] = false;
    },

    //userTable row 클릭이벤트
    clickListRow(e, { item }) {

      this.M810501P01Param = {
        headerTitle: "게시판 상세내역",
        data : {ASP_NEWCUST_KEY : this.ASP_NEWCUST_KEY, gridListDataText : item},
        DATA_FLAG : "U",
        p02 : true,
      };

      this.showDialog('M810501P01')


    },

    async getGridList() {
      let requestData = {
        headers: {},
        sendData: {}
      };

      requestData.headers["URL"] = "/api/setting/board/manage/list";
      requestData.headers["SERVICE"] = "setting.board.manage";
      requestData.headers["METHOD"] = "list";
      requestData.headers["ASYNC"] = false;
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      //requestData.sendData["BRD_ID"] = this.$store.getters["userStore/GE_USER_ID"];
      //BRD_ID||BRD_NAME||USE_YN||ASP_NEWCUST_KEY
      requestData.sendData["BRD_ID"] = this.BRD_ID;
      requestData.sendData["BRD_NAME"] = this.BRD_NAME;
      requestData.sendData["USE_YN"] = this.USE_YN;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;

      let responseGridList = await this.common_postCall(requestData);
      let resHeader = responseGridList.HEADER;
      let resData = responseGridList.DATA;

      if(resHeader.TOT_COUNT == 0) {
        this.common_alert(this.alertMsg.noCntData, 'error')
        this.gridListDataText = [];
        return false;
      }

      let idx = 1;
      for (let i in resData) {
        let id = resData[i].BRD_ID + "_" + resData[i].BRD_NAME;
        resData[i]["id"]= id;
        resData[i]["index"] = idx++;
      }
      this.gridListDataText = resData;
      this.gridListItemSelected = [];
    },
  },

  mounted() {
    this.selBoxBiz = this.$store.getters["userStore/GE_USER_COMPANY"];
  
    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },

  computed: {

  },


};
</script>

<style></style>
